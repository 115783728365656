<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    // items: {
    //   type: Array,
    //   required: true,
    // }, 
  },
  data() {
    return {
      items:[],
      role:"",
      id:null,
      token:''
    }
  },
  mounted() {
    this.role = localStorage.getItem("NisourRole")
    this.id = localStorage.getItem("NisourTeacherId")
    this.token = localStorage.getItem("NisourToken")
    this.gR()
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  methods:{
    async gR() {
      if(this.role !=""){
      await this.axios
        .get(`pages?take=100&skip=0&role=${this.role}`)
        .then((res) => {
          console.log(res.data.items[0])
          this.items = [];
          this.items = res.data.items;
          if(this.role=="teacher"||this.role=="employee"){
          this.items.push({ icon: 'UserCheckIcon', href:`https://nuc.edu.iq/science-deps/staff/profile/${this.id}`,title:'visit profile' })
          }else if(this.role=="admin"){
            let link='https://eduportalv2.nuc.edu.iq'
            // let link='http://localhost:4200'
            this.items.push({ icon: 'UserCheckIcon', href:`${link}/#/auth/login/${this.token}`,title:'documents' })
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });

      }
    },
  }
}
</script>
